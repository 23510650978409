
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  modalController,
} from "@ionic/vue";
import videoModal from "./videoModal.vue";
import footerContactanos from "./footerContactanos.vue";
import headertoolbarstudio from "./header_toolbar_studio.vue";
import { Capacitor } from "@capacitor/core";

export default {
  name: "jingle",
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    footerContactanos,
    headertoolbarstudio,
  },
  data() {
    return {
      platform: "",
    };
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: videoModal,
        componentProps: {
          title: "Jingle",
          video: "assets/videos/Jingle.m4v",
        },
      });
      return modal.present();
    },
  },
  mounted() {
    this.platform = Capacitor.getPlatform();
  },
};
